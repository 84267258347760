import {
  useState,
  useEffect,
  useCallback,
  useContext,
  createContext,
} from "react";

import u, { ELocalKey } from "@/util";
import useRequest from "@/hooks/useRequest";

interface ReactNodeProps {
  children: React.ReactNode;
}

export interface IBanner {
  id: number;
  img: string;
  insert_time: number;
  url: string;
}

export interface ICategoryChild {
  id: number;
  parent_id: number;
  name: string;
  jump_name: string;
  status: number;
  icon: string;
  url: string;
}

export interface ICategory {
  id: number;
  name: string;
  jump_name: string;
  status: number;
  icon: string;
  url: string;
  channel: string;
  child: ICategoryChild[];
}

export interface IList {
  title: string;
  jump_name: string;
  list: [];
}

export interface IPicIndexResponse {
  banners: IBanner[];
  categories: ICategory[];
}

export interface IPicIndexListResponse {
  list_1: IList;
  list_2: IList;
  list_3: IList;
  list_4: IList;
  list_5: IList;
}

export type PictureContextType = {
  banners: IBanner[];
  categories: ICategory[];
  lists: IList[];
};

export const PictureContext = createContext<PictureContextType | null>({
  banners: [],
  categories: [],
  lists: [],
});

export const PictureProvider: React.FC<ReactNodeProps> = ({ children }) => {
  const [banners, setBanners] = useState<IBanner[]>([]);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [lists, setLists] = useState<IList[]>([]);

  const { makeRquest: requestPicIndex } = useRequest("pic/index", "post");
  const { makeRquest: requestPicIndexList } = useRequest("pic/index-list", "post");

  const fetchPicIndex = useCallback(async () => {
   try{
    const { data }: { data: IPicIndexResponse } = await requestPicIndex();

    if (data?.categories) {
      const __v = Object.values(data?.categories);
      setCategories(__v);
      localStorage.setItem(ELocalKey._CATER, u.encrypt(JSON.stringify(__v)));
    }

    if (data?.banners) {
      const __v = Object.values(data?.banners);
      setBanners(__v);
      localStorage.setItem(ELocalKey._BANNER, u.encrypt(JSON.stringify(__v)));
    }
   }catch(err){
    console.log(err)
   }
    
  }, [requestPicIndex]);

  const fetchPicIndexList = useCallback(async () => {
    try{
      const { data }: { data: IPicIndexListResponse } = await requestPicIndexList();
        
      const __v = Object.keys(data).map((key: string) => (data as any)[key]);
      setLists(__v);
      localStorage.setItem(ELocalKey._INDEXLIST, u.encrypt(JSON.stringify(__v)));

      // if (data?.list_1 && data?.list_2 && data?.list_3 && data?.list_4 && data?.list_5) {
      //   const __v = Object.values([data.list_1, data.list_2, data.list_3, data.list_4, data.list_5]);
      //   setLists(__v);
      //   localStorage.setItem(ELocalKey._INDEXLIST, u.encrypt(JSON.stringify(__v)));
      // }
    }catch(err){
      console.log(err)
    }
    
  }, [requestPicIndexList]);

  useEffect(() => {
    try {
      const categoryData = localStorage.getItem(ELocalKey._CATER);
      const categoryVal = JSON.parse(u.decrypt(categoryData));

      const BannersData = localStorage.getItem(ELocalKey._BANNER);
      const bannerVal = JSON.parse(u.decrypt(BannersData));

      setCategories(categoryVal);
      setBanners(bannerVal);
    } catch {
      fetchPicIndex();
    }
  }, []);

  useEffect(() => {
    u.clearExpireLocalStorage();
    try {
      const listData = localStorage.getItem(ELocalKey._INDEXLIST);
      const listVal = JSON.parse(u.decrypt(listData));

      setLists(listVal);
    } catch {
      fetchPicIndexList();
    }
  }, []);

  return (
    <PictureContext.Provider value={{ banners, categories, lists }}>
      {children}
    </PictureContext.Provider>
  );
};

export const usePictureIndex = () => {
  return useContext(PictureContext) as PictureContextType;
};
