import { FC, useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import logo from "../../assets/logo-pic.png";
import logoMain from "../../assets/logo-main.png";
import logoCili from "../../assets/logo-cili.png";
import hamburger from "../../assets/hamburger.png";
import close from "../../assets/close.png";
import arrowBottom from "../../assets/icon-arrow-bottom.svg";
import arrowUp from "../../assets/icon-arrow-top.svg";

import styles from "./Header.module.css";
import { usePictureIndex } from "@/contexts/pic.context";
import Image from "../image/Image";
import { useHeader } from "@/contexts/header.context";
import SegmentedControl from "../segmented/Segmented";

interface IHeader {
  isScroll?: boolean;
}
const Header: FC<IHeader> = ({ isScroll = false }) => {
  const navigate = useNavigate();
  const { isMobileHeaderChildOpen, setIsMobileHeaderChildOpen } = useHeader();
  const { categories } = usePictureIndex();

  const [menuChildIsShown, setMenuChildIsShown] = useState(false);
  const [sidemenuOpen, setSidemenuOpen] = useState(false);
  const [mainDomainName, setMainDomainName] = useState("/");
  const [ciliDomainName, setCiliDomainName] = useState("/");
  const [selectedValue, setSelectedValue] = useState("featured");

  const cssStyle = useMemo(() => {
    const _style = `${styles.headerContainer} ${styles.header}`;
    return isScroll ? `${_style} ${styles.scroll}` : _style;
  }, [isScroll]);

  const handleCategoryClick = (category: any) => {
    setSidemenuOpen(false);
    navigate(
      `/page/category/${category.jump_name}?name=${category.name}&page=1`
    );
  };

  const getDomainName = () => {
    const hostname = window.location.origin;
    const domain = hostname.replace("pic.", "");
    setMainDomainName(domain);
    const ciliDomain = hostname.replace("pic.", "download.");
    setCiliDomainName(ciliDomain);
  };

  useEffect(() => {
    getDomainName();
  }, []);

  return (
    <>
      <div className={styles.headerSection}>
        <div className={cssStyle}>
          <div className={styles.headerCard}>
            <div
              className={styles.headerMenuLogo}
              onMouseLeave={() => setMenuChildIsShown(false)}
            >
              <div
                className={styles.headerMenuShow}
                onMouseEnter={() => setMenuChildIsShown(true)}
              >
                <Link to="/main">
                  <img
                    src={logo}
                    alt="logo"
                    className={styles.headerMenuMargin}
                  />
                  {menuChildIsShown ? (
                    <img
                      src={arrowUp}
                      alt="arrow-top"
                      className={styles.arrowIconImg}
                    />
                  ) : (
                    <img
                      src={arrowBottom}
                      alt="arrow-bottom"
                      className={styles.arrowIconImg}
                    />
                  )}
                </Link>
              </div>
              {menuChildIsShown && (
                <div className={styles.headerMenuChild}>
                  <ul>
                    <li>
                      <Link
                        to={mainDomainName}
                        className={`${styles.otherMenu} ${styles.otherMenuMain}`}
                      >
                        <img src={logoMain} alt="logo" />
                      </Link>
                    </li>
                    <li>
                      <Link to={ciliDomainName} className={styles.otherMenu}>
                        <img src={logoCili} alt="logo" />
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            {/* <div className={styles.headerLogo}>
              <Link to="/">
                <img width={130} height={25} src={logo} alt="logo" />
              </Link>
              <div className={styles.logoDomain}>{window.location.host}</div>
            </div> */}
          </div>
        </div>
      </div>
      <div className={styles.mobileHeaderSection}>
        <div className={styles.mobileHeaderContainer}>
          <div
            className={`${styles.mobileHeader} ${
              !isMobileHeaderChildOpen && `${styles.mobileHeaderShadow}`
            }`}
          >
            <div className={styles.hamburgerSlide}>
              <img
                width={30}
                height={30}
                src={hamburger}
                alt="hamburger"
                onClick={() => setSidemenuOpen(true)}
              />
            </div>
            <div className={styles.headerCard}>
              <Link to="/">
                <img src={logo} alt="logo" />
              </Link>
              {isMobileHeaderChildOpen ? (
                <img
                  width={20}
                  height={20}
                  src={arrowUp}
                  alt="arrow-top"
                  className={styles.arrowIconImg}
                  onClick={() => setIsMobileHeaderChildOpen(false)}
                />
              ) : (
                <img
                  width={20}
                  height={20}
                  src={arrowBottom}
                  alt="arrow-bottom"
                  className={styles.arrowIconImg}
                  onClick={() => setIsMobileHeaderChildOpen(true)}
                />
              )}
            </div>
          </div>
          {isMobileHeaderChildOpen && (
            <div className={styles.mobileChildHeader}>
              <div
                className={styles.mobileChildItem}
                style={{ marginLeft: "-5px" }}
              >
                <Link to={mainDomainName}>
                  <img src={logoMain} alt="logo" />
                </Link>
              </div>
              <div className={styles.mobileChildItem}>
                <Link to={ciliDomainName}>
                  <img src={logoCili} alt="logo" />
                </Link>
              </div>
            </div>
          )}
        </div>
        <div
          className={`${styles.mobileSlideContent} ${
            sidemenuOpen ? styles.open : ""
          }`}
        >
          <div className={styles.sidemenuHeader}>
            <div
              className={styles.closeSidemenu}
              onClick={() => setSidemenuOpen(false)}
            >
              <img width={23} height={23} src={close} alt="close" />
            </div>
            <Link to="/">
              <img width={110} height={20} src={logo} alt="logo" />
            </Link>
          </div>
          <SegmentedControl
            name="categorySegmented"
            callback={(val: any) => setSelectedValue(val)}
            controlRef={useRef()}
            segments={[
              {
                label: "猫咪精选",
                value: "featured",
                ref: useRef(),
              },
              {
                label: "免费专区",
                value: "free",
                ref: useRef(),
              },
            ]}
          />
          {selectedValue === "featured" && (
            <div className={styles.sidemenuCategories}>
              {categories[0]?.child?.map((category, index) => {
                return (
                  <Image
                    key={index}
                    srcValue={category && category.icon}
                    className={styles.sidemenuCategoriesImage}
                    onClick={() => handleCategoryClick(category)}
                    size={true}
                  />
                );
              })}
            </div>
          )}
          {selectedValue === "free" && (
            <div className={styles.sidemenuCategories}>
              {categories[1]?.child?.map((category, index) => {
                return (
                  <Image
                    key={index}
                    srcValue={category && category.icon}
                    className={styles.sidemenuCategoriesImage}
                    onClick={() => handleCategoryClick(category)}
                    size={true}
                  />
                );
              })}
            </div>
          )}
        </div>
        <div
          className={`${styles.overlay} ${sidemenuOpen ? styles.open : ""}`}
          onClick={() => setSidemenuOpen(false)}
        ></div>
      </div>
    </>
  );
};
export default Header;
