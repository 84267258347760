import { FC, useEffect, useState } from "react";
import styles from "./HotArea.module.css";
import { usePictureIndex } from "@/contexts/pic.context";
import { useNavigate, useSearchParams } from "react-router-dom";
import Image from "../image/Image";
import { filter, has, get } from "lodash";

const HotArea: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { categories } = usePictureIndex();

  const [currentSelectedId, setCurrentSelectedId] = useState(0);

  const handleCategoryClick = (item: any) => {
    setCurrentSelectedId(item?.id);
    navigate(`/page/category/${item.jump_name}?name=${item.name}&page=1`);
  };

  useEffect(() => {
    const name = searchParams.get("name");
    const matchingCategories = filter(categories, { child: [{ name: name }] });
    if (matchingCategories && matchingCategories.length > 0) {
      const find =
        matchingCategories &&
        matchingCategories[0]?.child.find((val: any) => val.name === name);

      find && setCurrentSelectedId(find?.id);
    }
  }, [categories]);

  return (
    <div className={styles.hotArea}>
      {/* 免费专区 */}
      <div className={styles.hotAreaContent}>
        <div className={styles.hotAreaTitle}>
          <p>{categories && categories[0]?.name}</p>
        </div>
        {categories &&
          categories[0]?.child?.map((item, index) => {
            return (
              <div
                className={styles.contentItem}
                key={index}
                onClick={() => handleCategoryClick(item)}
              >
                <div
                  className={
                    currentSelectedId === item.id
                      ? styles.itemImgSelected
                      : styles.itemImg
                  }
                >
                  <Image
                    key={index}
                    srcValue={item && item.icon}
                    className={styles.hotAreaImg}
                    size={true}
                  />
                </div>
                {/* <div className={styles.itemName}>{item.name}</div> */}
              </div>
            );
          })}
      </div>
      {/* 猫咪精选 */}
      <div className={styles.hotAreaContent}>
        <div className={styles.vipAreaTitle}>
          <p>{categories && categories[1]?.name}</p>
        </div>
        {categories &&
          categories[1]?.child?.map((item, index) => {
            return (
              <div
                className={styles.contentItem}
                key={index}
                onClick={() => handleCategoryClick(item)}
              >
                <div
                  className={
                    currentSelectedId === item.id
                      ? styles.itemImgSelectedFree
                      : styles.itemImg
                  }
                >
                  <Image
                    key={index}
                    srcValue={item && item.icon}
                    className={styles.hotAreaImg}
                    size={true}
                  />
                </div>
                {/* <div className={styles.itemName}>{item.name}</div> */}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default HotArea;
